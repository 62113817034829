import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About us" />
    <div className="container">
    <h1 className="PageH1">About us</h1>
    <p>Cynesige Web Development was founded on the belief that every company deserves a professional and unique website. Our mission is to deliver the best possible service for all customers. By adapting our working strategy to individually fit each and every company's ideas and budget, we will make sure you find both the process and final result satisfactory.</p>
   {/* <p>Cynesige web development is a web development agency from Stockholm, Sweden. We are a team of two students who builds and designs modern responsive websites for companies and individuals.</p> */}
    <h2>The team</h2>
    <p>We are a team of two students from Stockholm Sweden. We strive to provide the best possible experience for you even if it requires learning a new technology along the way. Do not hesitate to get in touch with your ideas and vision so that we can come up with a solution that fits your needs.</p>
    </div>
  </Layout>
)

export default AboutPage
